<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Boleto Bancário",
        title: "Boleto",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/BilletOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "registeredDate",
            title: "Dt Emissão",
            type: "date",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "recipientName",
            title: "Destinatário",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:130px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "document",
            title: "Doc",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "dueDateStr",
            title: "Dt Vcto",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "value",
            title: "Valor",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "statusName",
            title: "Situação",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
        rules: [
          {
            name: "registeredDate",
            title: "Data de Emissão",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "customerSupplierId",
            title: "Destinatário",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "status",
            title: "Status",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Emitido", value: 1 },
              { title: "Alteração Solicitada", value: 8 },
              { title: "Alterado", value: 9 },
              { title: "Liquidado", value: 2 },
              { title: "Registrado", value: 3 },
              { title: "Baixa Solicitada", value: 5 },
              { title: "Baixa", value: 6 },
              { title: "Cancelado", value: 7 },
              { title: "Rejeitado", value: 4 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "dueDate",
            title: "Data de Vencimento",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "value",
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Valor",
                value: "sum_Value",
                aggregate: {
                  title: "Total Valor",
                  value: "sumValue",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
